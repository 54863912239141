import React, { useEffect } from "react";
import "./about.css";

export default function About(props) {
  useEffect(() => {
    const aboutSection = document.querySelector(".about");
    const aboutImage = document.querySelector(".about img");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            aboutImage.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing after the animation starts
          }
        });
      },
      {
        threshold: 0.8, // Adjust as needed
      }
    );

    observer.observe(aboutSection);

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  return (
    <div className="about">
      <img
        src={props.image}
        alt="A person smiling kneeling down between two dogs on a sandy beach."
      />
      <div className="about-title-container">
        <p className="Pill Pill-blue">About me</p>
        <h3>{props.title}</h3>
      </div>
      <p>{props.body}</p>
    </div>
  );
}
