import React from "react";
import Navigation from "../components/nav/nav";
import ProjectCard from "../components/project-card/project-card";
import Footer from "../components/footer-section/footer-section";

import Placeholder from "../assets/images/placeholder.png";
import WeatherApp from "../assets/images/weather-app-final-still.png";
import worldClock from "../assets/images/world-clock-final-still.png";

export default function projects() {
  return (
    <div>
      <div className="main-content">
        <Navigation />
        <div className="project-page-title">
          <h1>All projects</h1>
          <div className="project-filter">
            <p className="Pill">All projects</p>
            <p className="Pill">UI Design</p>
            <p className="Pill">3D</p>
            <p className="Pill">Development</p>
          </div>
        </div>
        <div className="projects-list">
          <ProjectCard
            title="Weather App"
            labels="Javascript / API / UI Design"
            image={WeatherApp}
            link="/weather-app"
          />
          <ProjectCard
            title="World Clock"
            labels="Javascript / API / UI Design"
            image={worldClock}
            link="/world-clock"
            className="stagger-margin"
          />
          <ProjectCard
            title="Title"
            labels="Label"
            image={Placeholder}
            link="/"
          />
          <ProjectCard
            title="Title"
            labels="Label"
            image={Placeholder}
            link="/"
            className="stagger-margin"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
