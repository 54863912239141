import React from "react";

import FadeInSection from "../animations/fade-in";
import Navigation from "../components/nav/nav";
import HeroSection from "../components/hero/hero";
import Skills from "../components/skills-section/skills";
import FeatureProjects from "../components/feature-projects/feature-projects";
import About from "../components/about-section/about";
import Footer from "../components/footer-section/footer-section";

import AboutImage from "../assets/images/about-image.png";

export default function Home() {
  return (
    <div className="portfolio">
      <div className="main-content">
        <Navigation />
        <FadeInSection>
          <HeroSection />
        </FadeInSection>

        <FadeInSection>
          <Skills />
        </FadeInSection>
        <FeatureProjects />
        <FadeInSection>
          <About
            image={AboutImage}
            title="Hello! I’m Emily Richards, a Digital Designer based in South Wales, UK. I'm passionate about anything that blends design and technology to create impactful user experiences."
            body="With a background in UI design, branding, design systems, 3D art, and emerging technologies such as XR and AI, I thrive on tackling new challenges and pushing boundaries. If you're looking for someone who brings creativity, a unique perspective, good vibes, and the occasional dog visit on Zoom, I would love to hear from you."
          />
        </FadeInSection>
        <Footer />
      </div>
    </div>
  );
}
