import React from "react";
import "./project-card.css";
import Arrow from "../../assets/icons/arrow.svg";

export default function ProjectCard(props) {
  const className = `project-card ${props.className || ""}`.trim();

  return (
    <a href={props.link} className={className}>
      <div
        className="project-card-image"
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>
      <div className="project-card-details">
        <div>
          <h3>{props.title}</h3>
          <p>{props.labels}</p>
        </div>
        <img src={Arrow} alt="" className="card-icon"></img>
      </div>
    </a>
  );
}
