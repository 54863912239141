import React, { useEffect, useRef, useState } from "react";
import "./fade-in.css";

const FadeInSection = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const checkIfVisible = () => {
      if (!sectionRef.current) return;

      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY || window.pageYOffset;
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.clientHeight;

      const isSectionVisible =
        scrollY + windowHeight > sectionTop + sectionHeight / 2 &&
        scrollY < sectionTop + sectionHeight / 2;

      if (isSectionVisible) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", checkIfVisible);

    checkIfVisible();

    return () => {
      window.removeEventListener("scroll", checkIfVisible);
    };
  }, []);

  return (
    <div ref={sectionRef} className={`fade-in ${isVisible ? "active" : ""}`}>
      {children}
    </div>
  );
};

export default FadeInSection;
