import React from "react";
import "./feature-project-card.css";
import Arrow from "../../assets/icons/arrow.svg";

export default function FeatureProjectCard(props) {
  return (
    <a href={props.link} className="feature-project-card">
      <div className="card-left">
        <div className="card-details">
          <h3>{props.title}</h3>
          <p>{props.labels}</p>
        </div>
        <img src={Arrow} alt="" className="card-icon"></img>
      </div>
      <div className="card-image">
        <img src={props.image} alt="" className="card-image"></img>
      </div>
    </a>
  );
}
