import React from "react";

import Navigation from "../components/nav/nav";
import ProjectHeader from "../components/project-header/project-header";
import ProjectFeatureImage from "../components/project-feature-image/project-feature-image";
import SplitText from "../components/split-text/split-text";
import SplitList from "../components/split-list/split-list";
import SplitImages from "../components/split-images/split-images";
import Checkerboard from "../components/checker-board/checker-board";
import worldClockWireframe from "../assets/images/world-clock-wireframe.png";
import worldClockPrototype from "../assets/gif/world-clock-prototype.gif";
import worldClockFinal from "../assets/gif/world-clock-final.gif";
import worldClockFinalStill from "../assets/images/world-clock-final-still.png";
import Footer from "../components/footer-section/footer-section";
import MoreProjects from "../components/more-projects/more-projects";

export default function WorldClock() {
  return (
    <div>
      <div className="main-content">
        <Navigation />
        <ProjectHeader
          title="World Clock"
          overview="I developed a world clock app to enhance my JavaScript proficiency and gain experience with APIs. The app displays the current time for a selection of pre-defined cities and offers users the ability to select their current location or explore additional options through a dropdown menu."
          button="Launch World Clock"
          link="https://emily-richards-world-clock.netlify.app/"
          type="Javascript / API / UI Design"
          technologies="Javascript, HTML, CSS, API, Figma"
          year="2024"
        />
      </div>
      <ProjectFeatureImage image={worldClockFinal} backgroundColor="#EDF4FE" />
      <div className="main-content challenge">
        <SplitText
          title="Challenge"
          body="The goal of this project, undertaken as part of my coding bootcamp, was to advance my JavaScript skills by developing a world clock application. The project focused on fetching and displaying real-time data for selected cities, allowing users to view the current time in different locations and providing an interactive dropdown menu for selecting additional cities or their current location."
        />

        <SplitList
          planning="I researched similar applications to gather inspiration for the UI and to understand common features. I then created a wireframe in Figma for the design."
          implementation="In the implementation phase, I developed the world clock using HTML, CSS, and JavaScript, with Moment.js to manage and display the time. I then implemented features such as a dropdown menu for selecting various cities and providing the option to display the user's current local time. Additionally, I focused on refining the app's UI design using CSS."
          testing="I tested the app's functionality, focusing on data accuracy, user input validation, and responsive design across various devices and browsers. "
        />

        <SplitImages left={worldClockWireframe} right={worldClockPrototype} />

        <Checkerboard
          title="Outcome"
          body="This project helped my proficiency in JavaScript, particularly through utilising of Moment.js for managing and presenting time-related data. It improved my proficiency in DOM manipulation, enabling me to dynamically update time displays and city information based on user selections. I also refined my UI design capabilities. Additionally, this project taught me the importance in planning and executing a development project from start to finish."
          button="Launch World Clock"
          link="https://emily-richards-world-clock.netlify.app/"
          image={worldClockFinalStill}
        />

        <MoreProjects
          rightImage={worldClockFinalStill}
          rightTitle="Title"
          rightLabel="Label"
          leftImage={worldClockFinalStill}
          leftTitle="Title"
          leftLabel="Label"
          link="/"
        />
      </div>
      <Footer />
    </div>
  );
}
