import React from "react";
import "./split-list.css";

export default function SplitText(props) {
  return (
    <div className="split-section split-list">
      <h3 className="left-side">Development process</h3>
      <ol className="right-side">
        <li>
          <h4>1. Planning</h4>
          <p>{props.planning}</p>
        </li>
        <li>
          <h4>2. Implementation</h4>
          <p>{props.implementation}</p>
        </li>
        <li>
          <h4>3. Testing</h4>
          <p>{props.testing}</p>
        </li>
      </ol>
    </div>
  );
}
