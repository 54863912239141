import React from "react";

export default function SplitText(props) {
  return (
    <div className="split-section">
      <h3 className="left-side">{props.title}</h3>
      <p className="right-side">{props.body}</p>
    </div>
  );
}
