import React from "react";
import ReactDOM from "react-dom";
import "./styles/portfolio.css";
import "./styles/index.css";
import "./styles/projects.css";

import Portfolio from "./portfolio";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <Router>
    <Portfolio />
  </Router>,
  document.getElementById("root")
);
