import React from "react";
import "./split-images.css";

export default function SplitImages(props) {
  return (
    <div className="split-images">
      <div
        className="split-images-container image-left"
        style={{ backgroundImage: `url(${props.left})` }}
      ></div>
      <div
        className="split-images-container image-right"
        style={{ backgroundImage: `url(${props.right})` }}
      ></div>
    </div>
  );
}
