import React from "react";

import FadeInSection from "../animations/fade-in";
import Navigation from "../components/nav/nav";
import ProjectHeader from "../components/project-header/project-header";
import ProjectFeatureImage from "../components/project-feature-image/project-feature-image";
import SplitText from "../components/split-text/split-text";
import SplitList from "../components/split-list/split-list";
import SplitImages from "../components/split-images/split-images";
import Checkerboard from "../components/checker-board/checker-board";
import Footer from "../components/footer-section/footer-section";
import MoreProjects from "../components/more-projects/more-projects";

import weatherAppBackground from "../assets/images/weather-app-background.png";
import weatherAppWireframe from "../assets/images/weather-app-wireframe.png";
import weatherAppPrototype from "../assets/gif/weather-app-prototype.gif";
import weatherAppFinal from "../assets/gif/weather-app-final.gif";
import weatherAppFinalStill from "../assets/images/weather-app-final-still.png";

export default function FitnessWebsite() {
  return (
    <div>
      <div className="main-content">
        <Navigation />
        <ProjectHeader
          title="Responsive Fitness Website"
          overview="I developed a responsive fitness website to work on my web development skills, focusing particularly on creating a seamless experience across different devices and screen sizes. The website serves as a landing page for a fitness trainer to promote their services."
          button="Launch website"
          link="https://fitness-training.netlify.app/"
          type="Responsive design / UI Design"
          technologies="HTML, CSS, Figma"
          year="2024"
        />
      </div>
      <ProjectFeatureImage
        image={weatherAppFinal}
        backgroundImage={weatherAppBackground}
      />

      <div className="main-content challenge">
        <FadeInSection>
          <SplitText
            title="Challenge"
            body="The aim of this project, as part of my coding bootcamp, was to understand how to fetch and display data from an external API using JavaScript by creating a weather app. This project provided a hands-on experience in working with APIs and developing dynamic, data-driven web applications."
          />
        </FadeInSection>

        <FadeInSection>
          <SplitList
            planning="I researched similar applications to gather inspiration for the UI and to understand common features. I then created a wireframe in Figma for the design."
            implementation="In the implementation phase, I developed the app using JavaScript to fetch data from the OpenWeather API and display the weather information in the UI. I then implemented features such as displaying the temperature, weather conditions, and icons representing the weather. Then I refined the UI design using CSS."
            testing="To test the functionality of the app I searched for different locations and ensured accurate data retrieval and display."
          />
        </FadeInSection>

        <FadeInSection>
          <SplitImages left={weatherAppWireframe} right={weatherAppPrototype} />
        </FadeInSection>

        <FadeInSection>
          <Checkerboard
            title="Outcome"
            body="This project deepened my understanding of JavaScript and API integration. It significantly improved my skills in DOM manipulation, enabling me to dynamically update the webpage with real-time weather information. Additionally, I honed my UI design skills, focusing on creating a user-friendly design and layout. It also taught me the importance in planning and executing a development project from start to finish."
            button="Launch Weather App"
            link="https://emily-richards-weather-app.netlify.app/"
            image={weatherAppFinalStill}
          />
        </FadeInSection>

        <FadeInSection>
          <MoreProjects
            rightImage={weatherAppFinalStill}
            rightTitle="Title"
            rightLabel="Label"
            leftImage={weatherAppFinalStill}
            leftTitle="Title"
            leftLabel="Label"
            link="/"
          />
        </FadeInSection>
      </div>
      <Footer />
    </div>
  );
}
