import React from "react";
import "./skills.css";
import UiIcon from "../../assets/icons/ui-icon.svg";
import BrandIcon from "../../assets/icons/brand-icon.svg";
import DeveloperIcon from "../../assets/icons/development-icon.svg";
import CubeIcon from "../../assets/icons/3d-icon.svg";
import IllustrationIcon from "../../assets/icons/illustration-icon.svg";

export default function Skills() {
  return (
    <div className="skills">
      <p className="Pill">What I do</p>
      <ul>
        <li>
          <img src={UiIcon}></img>
          <h3>UI Design</h3>
        </li>
        <li>
          <img src={BrandIcon}></img>
          <h3>Branding</h3>
        </li>
        <li>
          <img src={DeveloperIcon}></img>
          <h3>Front-End Development</h3>
        </li>
        <li>
          <img src={CubeIcon}></img>
          <h3>3D Design</h3>
        </li>
        <li>
          <img src={IllustrationIcon}></img>
          <h3>Illustration</h3>
        </li>
      </ul>
    </div>
  );
}
