import React from "react";
import "./project-header.css";

export default function ProjectHeader(props) {
  return (
    <div className="project-header">
      <h1>{props.title}</h1>
      <div className="project-overview">
        <p className="p-large">{props.overview}</p>
        <a
          href={props.link}
          className="button secondary-button"
          target="_blank"
          rel="noreferrer"
        >
          {props.button}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0008 8.4L6.10078 19.3C5.91745 19.4833 5.68411 19.575 5.40078 19.575C5.11745 19.575 4.88411 19.4833 4.70078 19.3C4.51745 19.1167 4.42578 18.8833 4.42578 18.6C4.42578 18.3167 4.51745 18.0833 4.70078 17.9L15.6008 7H10.0008C9.71745 7 9.47995 6.90417 9.28828 6.7125C9.09661 6.52083 9.00078 6.28333 9.00078 6C9.00078 5.71667 9.09661 5.47917 9.28828 5.2875C9.47995 5.09583 9.71745 5 10.0008 5H18.0008C18.2841 5 18.5216 5.09583 18.7133 5.2875C18.9049 5.47917 19.0008 5.71667 19.0008 6V14C19.0008 14.2833 18.9049 14.5208 18.7133 14.7125C18.5216 14.9042 18.2841 15 18.0008 15C17.7174 15 17.4799 14.9042 17.2883 14.7125C17.0966 14.5208 17.0008 14.2833 17.0008 14V8.4Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <div className="project-details">
        <ul>
          <li>
            <p className="highlight-p">Type</p>
            <p className="right-align">{props.type}</p>
          </li>
          <li>
            <p className="highlight-p">Technologies</p>
            <p className="right-align">{props.technologies}</p>
          </li>
          <li className="border-none">
            <p className="highlight-p">Year</p>
            <p className="right-align">{props.year}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
