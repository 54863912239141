import React from "react";
import "./feature-projects.css";
import FeatureProjectCard from "../feature-project-card/feature-project-card";
import FadeInSection from "../../animations/fade-in";
import Arrow from "../../assets/icons/arrow.svg";
import Placeholder from "../../assets/images/placeholder.png";

export default function FeatureProjects() {
  return (
    <div className="feature-projects">
      <FadeInSection>
        <FeatureProjectCard
          link="/"
          title="Zappar Branding"
          labels="Branding / UI Design / 3D"
          image={Placeholder}
        />
      </FadeInSection>
      <FadeInSection>
        <FeatureProjectCard
          link="/"
          title="Zapworks Designer"
          labels="Branding / UI Design / 3D"
          image={Placeholder}
        />
      </FadeInSection>
      <FadeInSection>
        <FeatureProjectCard
          link="/"
          title="Zapbox"
          labels="Branding / UI Design / 3D"
          image={Placeholder}
        />
      </FadeInSection>
      <FadeInSection>
        <a href="/projects" className="more-projects-link">
          <p className="p-large">View all projects</p>
          <img src={Arrow} alt="" className="card-icon"></img>
        </a>
      </FadeInSection>
    </div>
  );
}
