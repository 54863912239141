import React from "react";
import "./project-feature-image.css";

export default function FeatureImage(props) {
  const { backgroundImage, backgroundColor, image } = props;

  const backgroundStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : { backgroundColor: backgroundColor };

  return (
    <div className="feature-image" style={backgroundStyle}>
      <img src={image} alt="" className="feature-gif" />
    </div>
  );
}
